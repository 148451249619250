import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingBackendUserObjects: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields('backendUser', {
      backendUserIds: 'allIds',
      backendUserIdsCached: "cacheAllIds",
    }),
    ...mapGetters('backendUser', {
      backendUserItems: 'list',
      findBackendUser: 'find'
    }),
  },
  methods: {
    ...mapActions('backendUser', {
      retrieveBackendUser: 'load',
    }),
    backendUserById(backendUserId) {
      if (!this.backendUserIdsCached.includes(backendUserId) && !this.loadingBackendUserObjects.includes(backendUserId)) {
        this.loadingBackendUserObjects.push(backendUserId);
        this.retrieveBackendUser(decodeURIComponent(backendUserId));
      }
      return this.findBackendUser(backendUserId) ?? null;
    },
    getBackendUserItem(backendUserId, type) {
      if (backendUserId !== null) {
        let backendUser = this.backendUserById(backendUserId);
        if (backendUser) {
          return backendUser[type];
        }
      }
      return '';
    },
  }
};
