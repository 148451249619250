<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col>
          <InputEditor
            v-model="item.description"
            :error-messages="descriptionErrors"
            :label="$t('description')"
            :required="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>{{ $t('Attachments') }}:</p>
          <v-list v-if="attachments">
            <v-list-item v-for="attachment in attachments" :key="attachment.id">
              <v-list-item-content>
                <v-list-item-title>
                  <button @click="getAndDownloadDocument(attachment['@id'])">
                    {{ attachment.name }}
                  </button>
                  <v-icon class="ml-4" color="red lighten-1" @click="deleteAttachment(attachment)">delete</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-file-input
            :rules="imageRules"
            :label="$t('Attachments')"
            v-model="attachment"
            accept="application/pdf,image/*"
            prepend-icon="mdi-attachment"
          />
          <v-btn class="ml-auto" color="primary" @click="uploadAttachment()">{{ $t("Upload") }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {validationMixin} from "vuelidate";
import has from "lodash/has";
import {required} from "vuelidate/lib/validators";
import DocumentMixin from "../../mixins/DocumentMixin";
import InputEditor from "@/components/InputEditor";

export default {
  name: "IssueUpdateForm",
  mixins: [DocumentMixin, validationMixin],
  components: {
    InputEditor
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    issueId: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },

    loading: {
      type: Boolean,
      required: true,
    },

    initialValues: {
      type: Object,
      default: () => {
      },
    },

    handleSubmit: {
      type: Function,
      required: false,
    },

    handleReset: {
      type: Function,
      required: false,
    },
    uploadAttachment: {
      type: Function,
      required: true,
    },
    deleteAttachment: {
      type: Function,
      required: true,
    },
  },
  mounted() {
  },
  data() {
    return {
      name: null,
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      attachment: null,
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    attachments() {
      return this.resolveDocuments(this.item.attachments);
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.item.description.$dirty) {
        return errors;
      }
      has(this.violations, "description") && errors.push(this.description.fee);
      !this.$v.item.description.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {
    log(m){console.log(m)},
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
  },
  validations: {
    item: {
      description: {
        required,
      },
    },
  },
};
</script>
