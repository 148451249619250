<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item["@id"]}` }}</v-toolbar-title>
      </template>
    </Toolbar>
    <div v-if="item">
      <v-card class="mt-4 mb-3 mx-lg-auto mx-sm-4" max-width="860px" flat>
        <v-card>
          <div class="d-md-flex flex-md-nowrap justify-md-space-between">
            <div>
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-subtitle>
                <div>
                  vom {{ formatDateTime(item.createdAt, "LLLL") }}
                </div>
                <div class="d-flex flex-nowrap justify-start">
                  <div class="mr-3">
                    <strong>{{ $t("Start") }}:</strong> {{ formatDateTime(item.start) }}
                  </div>
                  <div>
                    <strong>{{ $t("End") }}:</strong> {{ formatDateTime(item.end) }}
                  </div>
                </div>
                <div class="d-flex flex-nowrap justify-start">
                  <div class="mr-3">
                    <strong>{{ $t("AssignedTo") }}:</strong>
                    <template v-if="assignedTo">
                      {{
                        getProfileItemBasics(assignedTo.profile, 'firstName') ?
                            getProfileItemBasics(assignedTo.profile, 'firstName') :
                        assignedTo.email
                      }}
                      {{
                        getProfileItemBasics(assignedTo.profile, 'lastName') ?
                            getProfileItemBasics(assignedTo.profile, 'lastName') :
                        ''
                      }}
                    </template>
                  </div>
                  <div>
                    <strong>{{ $t("Author") }}:</strong>
                    <template v-if="author">
                       {{
                        getProfileItemBasics(author.profile, 'firstName') ?
                            getProfileItemBasics(author.profile, 'firstName') :
                        author.email
                      }}
                      {{
                        getProfileItemBasics(author.profile, 'lastName') ?
                            getProfileItemBasics(author.profile, 'lastName') :
                        ''
                      }}
                    </template>
                  </div>
                </div>
              </v-card-subtitle>
            </div>
            <div class="ma-3">
              <v-menu
                v-model="statusMenu"
                bottom right origin="top left"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-chip :color="statusColors[currentStatus]" class="mr-3" v-on="on">
                    {{ $t("Status") }}: {{ $t(statusText[currentStatus]) }}
                    <v-icon right small>mdi-dots-vertical</v-icon>
                  </v-chip>
                </template>
                <v-card width="200">
                  <v-list>
                    <v-list-item
                      v-for="(statusLabel, statusKey) in statusText" :key="`status-${statusKey}`"
                      @click="changeIssueStatus(statusKey)"
                      :disabled="item.status === statusKey"
                    >
                      <v-list-item-content> {{ statusLabel }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-chip :color="prioColors[item.priority]">
                <v-tooltip bottom v-if="item.assignedTo">
                  <template v-slot:activator="{on, attrs}">
                    {{ $t("Priority") }}:
                    <v-icon v-bind="attrs" v-on="on" class="ml-1 mr-1" small>{{ prioIcons[item.priority] }}</v-icon>
                  </template>
                  <span>
                    {{ $t(prioText[item.priority]) }}
                </span>
                </v-tooltip>
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <h3 class="mb-2">{{ $t('Description') }}:</h3>
            <div v-html="item.description"/>
            <div v-if="item.attachments">
              <v-divider/>
              <div class="d-flex flex-nowrap justify-start align-center mt-4">
                <strong class="mr-4">{{ $t("Attachments") }}:</strong>
                <v-sheet max-width="84%">
                  <v-slide-group multiple show-arrows>
                    <v-slide-item
                      v-for="attachment in attachments"
                      v-bind:key="attachment.id"
                      v-slot="{ active }">
                      <v-chip :color="active ? undefined : 'grey'" class="mx-2">
                        <button @click="getAndDownloadDocument(attachment['@id'])" style="color: white;">
                          <v-icon>mdi-paperclip</v-icon>
                          {{ attachment.name }}
                        </button>
                      </v-chip>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </div>
            </div>
          </v-card-text>
          <v-divider class="mx-1"></v-divider>
        </v-card>
        <v-card class="mt-10" v-for="(answer, index) in issueUpdates" :key="`answer-${index}`">
          <v-card-subtitle>
            <div>
              <strong>{{ $t("from") }}: </strong>
                {{
                getProfileItemBasics(getBackendUserItem(answer.author, 'profile'), 'firstName') ?
                        getProfileItemBasics(getBackendUserItem(answer.author, 'profile'), 'firstName') :
                    getBackendUserItem(answer.author, 'email')
                  }}

                  {{
                getProfileItemBasics(getBackendUserItem(answer.author, 'profile'), 'lastName') ?
                    getProfileItemBasics(getBackendUserItem(answer.author, 'profile'), 'lastName') : ''
                   }}
              <strong>am: </strong>{{ formatDateTime(answer.createdAt, "LLLL") }}
            </div>
          </v-card-subtitle>
          <v-card-text>
            <h3 class="mb-2">{{ $t('Description') }}:</h3>
            <div v-html="answer.description"/>
            <div v-if="answer.attachments.length > 0">
              <v-divider/>
              <div class="d-flex flex-nowrap justify-start align-center mt-4">
                <strong class="mr-4">{{ $t("Attachments") }}:</strong>
                <v-sheet max-width="84%">
                  <v-slide-group multiple show-arrows>
                    <v-slide-item
                      v-for="attachment in resolveDocuments(answer.attachments)"
                      v-bind:key="attachment.id"
                      v-slot="{ active }">
                      <v-chip :color="active ? undefined : 'grey'" class="mx-2">
                        <button @click="getAndDownloadDocument(attachment['@id'])" style="color: white;">
                          <v-icon>mdi-paperclip</v-icon>
                          {{ attachment.name }}
                        </button>
                      </v-chip>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </div>
            </div>
          </v-card-text>
          <v-divider class="mx-1" v-if="getUserIri() === answer.author"/>
          <v-card-actions v-if="getUserIri() === answer.author">
            <v-spacer/>
            <v-btn color="primary" outlined @click="issueUpdateDialogControlEdit(answer['@id'])">
              Editieren
            </v-btn>
          </v-card-actions>
          <IssueUpdateDialog
            v-model="showIssueUpdateDialogFormEdit"
            :issueId="item['@id']"
            :issueUpdateItem="answer"
            :handleUpdated="onIssueUpdateUpdated"
            :handleDeleted="onIssueUpdateDeleted"
            :title="$t('AddIssueUpdate')"
            @close="issueUpdateDialogControlEdit(answer['@id'], true)"
          />
        </v-card>
        <v-card flat class="mt-10">
          <v-btn color="primary" dark @click.prevent="issueUpdateDialogControlNew()">{{ $t("Reply") }}</v-btn>
          <IssueUpdateDialog
            v-model="showIssueUpdateDialogForm"
            :issueId="this.item['@id']"
            :issueUpdateItem="{}"
            :handle-created="onIssueUpdateCreated"
            :title="$t('AddIssueUpdate')"
            @close="issueUpdateDialogControlNew(true)"
          />
        </v-card>
      </v-card>
    </div>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import Loading from "../../components/Loading";
import ShowMixin from "../../mixins/ShowMixin";
import Toolbar from "../../components/Toolbar";
import IssueUpdateDialog from "../../components/issueUpdate/IssueUpdateDialog";
import DocumentMixin from "../../mixins/DocumentMixin";
import ProfileMixin from "@/mixins/ProfileMixin";
import BackendUserMixin from "@/mixins/BackendUserMixin";
import IssueUpdateMixin from "@/mixins/IssueUpdateMixin";

const servicePrefix = "Issue";

export default {
  name: "IssueShow",
  servicePrefix,
  components: {
    Loading,
    Toolbar,
    IssueUpdateDialog,
  },
  data() {
    return {
      showCardAttachments: [],
      showIssueUpdateDialogForm: [],
      showIssueUpdateDialogFormEdit: [],
      prioText: {
        10: this.$t("Low"),
        50: this.$t("Medium"),
        100: this.$t("High"),
      },
      prioColors: {
        10: 'green',
        50: 'blue',
        100: 'red',
      },
      prioIcons: {
        10: 'mdi-coffee',
        50: 'mdi-walk',
        100: 'mdi-fire',
      },
      statusMenu: false,
      statusText: {
        'new': this.$t("New"),
        'inProgress': this.$t("In progress"),
        'feedback': this.$t("Feedback"),
        'done': this.$t("Done"),
      },
      statusColors: {
        'new': 'orange',
        'inProgress': 'yellow',
        'feedback': 'blue',
        'done': 'green',
      },
    };
  },
  mixins: [DocumentMixin, ShowMixin, ProfileMixin, BackendUserMixin, IssueUpdateMixin],
  computed: {
    ...mapFields("issue", {
      isLoading: "isLoading",
    }),
    ...mapGetters("issue", ["find"]),
    author() {
      // Fix this, should just be iri, why there is an @type?
      return this.backendUserById(this.item.author);
    },
    assignedTo() {
      // Fix this, should just be iri, why there is an @type?
      return this.backendUserById(this.item.assignedTo);
    },
    issueUpdates() {
      return this.resolveIssueUpdateItems(this.item.answers);
    },
    attachments() {
      return this.resolveDocuments(this.item.attachments);
    },
    currentStatus() {
      return this.item.status ? this.item.status : 'new';
    }

  },
  methods: {
    log(mes) {
      console.log(mes);
    },
    ...mapActions("issue", {
      deleteItem: "del",
      reset: "reset",
      retrieve: "load",
      updateIssue: "update",
    }),
    controlCardAttachment(itemId) {
      if (this.showCardAttachments.includes(itemId)) {
        this.showCardAttachments.splice(this.showCardAttachments.indexOf(itemId), 1);
      } else {
        this.showCardAttachments.push(itemId);
      }
    },
    changeIssueStatus(statusKey) {
      this.updateIssue({
        '@id': this.item['@id'],
        'status': statusKey,
      });
    },
    issueUpdateDialogControlNew(close = false) {
      if (!close) {
        this.showIssueUpdateDialogForm.push("new");
      } else {
        this.showIssueUpdateDialogForm.splice(
          this.showIssueUpdateDialogForm.indexOf("new"),
          1
        );
      }
    },
    issueUpdateDialogControlEdit(id, close = false) {
      if (!close) {
        this.showIssueUpdateDialogFormEdit.push(id);
      } else {
        this.showIssueUpdateDialogFormEdit.splice(
          this.showIssueUpdateDialogFormEdit.indexOf(id),
          1
        );
      }
    },
    onIssueUpdateCreated(createdIssueUpdate) {
      this.item.answers.push(createdIssueUpdate['@id']);
    },
    onIssueUpdateUpdated(updatedIssueUpdate) {
      this.item.answers[
        this.item.answers.findIndex(
          (el) => el["@id"] === updatedIssueUpdate["@id"]
        )
        ] = updatedIssueUpdate;
    },
    onIssueUpdateDeleted(deletedIssueUpdate) {
      this.item.answers.splice(
        this.item.answers.findIndex(
          (el) => el["@id"] === deletedIssueUpdate["@id"]
        ),
        1
      );
    },
  },
};
</script>
