import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingIssueUpdateObjects: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields('issueUpdate', {
      issueUpdateIds: 'allIds',
      issueUpdateIdsCached: "cacheAllIds",
    }),
    ...mapGetters('issueUpdate', {
      issueUpdateItems: 'list',
      findIssueUpdate: 'find'
    }),
  },
  methods: {
    ...mapActions('issueUpdate', {
      retrieveIssueUpdate: 'load',
    }),
    issueUpdateById(issueUpdateId) {
      if (!this.issueUpdateIdsCached.includes(issueUpdateId) && !this.loadingIssueUpdateObjects.includes(issueUpdateId)) {
        this.loadingIssueUpdateObjects.push(issueUpdateId);
        this.retrieveIssueUpdate(decodeURIComponent(issueUpdateId));
      }
      return this.findIssueUpdate(issueUpdateId) ?? null;
    },
    resolveIssueUpdateItems(itemIds) {
      if(typeof itemIds === "undefined"){
        return [];
      }
      return itemIds.map((itemId) => {
        return this.issueUpdateById(itemId);
      }).filter(e => e);
    },
    getIssueUpdateItem(issueUpdateId, type) {
      if (issueUpdateId !== null) {
        let issueUpdate = this.issueUpdateById(issueUpdateId);
        if (issueUpdate) {
          return issueUpdate[type];
        }
      }
      return '';
    },
  }
};
