<template>
  <v-dialog v-model="showIssueUpdateDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <IssueUpdateForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          :ref="getFormRef()"
          :issueId="issueId"
          :uploadAttachment="uploadAttachment"
          :deleteAttachment="deleteAttachment"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="handleUpdated" @click="confirmDelete = true" class="red" color="danger" dark small>
          {{ $t("Delete") }}
        </v-btn>
        <v-btn text @click="showIssueUpdateDialog = false">
          {{ $t("Close") }}
        </v-btn>
        <v-spacer/>
        <v-btn class="mainAccent lighten-3" @click="resetForm">{{ $t("Reset") }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t("Submit") }}</v-btn>
      </v-card-actions>
      <ConfirmDelete
        :handle-delete="del"
        :visible="confirmDelete"
        @close="confirmDelete = false"
        v-if="handleUpdated"
      />
    </v-card>
    <Loading :visible="isLoading"/>
  </v-dialog>
</template>

<script>
import {createHelpers} from "vuex-map-fields";
import IssueUpdateForm from "./Form";
import NotificationMixin from "../../mixins/NotificationMixin";
import DocumentMixin from "../../mixins/DocumentMixin";
import ConfirmDelete from "../ConfirmDelete";
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import isEmpty from "lodash/isEmpty";

const servicePrefix = "IssueUpdate";

const {mapFields} = createHelpers({
  getterType: "issueUpdate/getField",
  mutationType: "issueUpdate/updateField",
});

export default {
  name: "IssueUpdateDialog",
  servicePrefix,
  mixins: [DocumentMixin, NotificationMixin],
  components: {
    Loading,
    IssueUpdateForm,
    ConfirmDelete,
  },
  props: {
    value: Array,
    title: {
      type: String,
      required: true,
    },
    handleCreated: {
      type: Function,
      required: false,
    },
    handleUpdated: {
      type: Function,
      required: false,
    },
    handleDeleted: {
      type: Function,
      required: false,
    },
    issueUpdateItem: {
      type: Object,
      required: false,
    },
    issueId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      item: {
        description: null,
        attachments: [],
      },
      confirmDelete: false,
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
      deleteMessage: this.$t("successfullyDeleted"),
      confirmAttachmentDeletionMessage: this.$t("confirmAttachmentDeletion"),
    };
  },
  computed: {
    ...mapFields([
      "error",
      "isLoading",
      "created",
      "deleted",
      "violations",
      "updated",
      "byId",
    ]),
    ...mapGetters("issueUpdate", ["find"]),
    formRefName() {
      if (this.handleUpdated) {
        return "updateForm_" + this.issueUpdateItem["@id"];
      }
      return "createForm";
    },
    formRef() {
      if (this.handleUpdated) {
        return this.$refs["updateForm_" + this.issueUpdateItem["@id"]];
      }
      return this.$refs.createForm;
    },
    showIssueUpdateDialog: {
      get() {
        return (
          this.value.indexOf(
            typeof this.issueUpdateItem["@id"] === "undefined"
              ? "new"
              : this.issueUpdateItem["@id"]
          ) > -1
        );
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapActions("issueUpdate", {
      create: "create",
      update: "update",
      resetData: "resetData",
      retrieve: "load",
      deleteItem: "del",
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.formRef.$v.item.$model;
        if (this.handleUpdated) {
          this.update(model);
        }
        if (this.handleCreated) {
          model["issue"] = this.issueId;
          this.create(model);
        }
      }
    },
    reset() {
      this.formRef.$v.$reset();
    },
    resetForm() {
      this.item = {description: null, attachments: []};
      this.formRef.$v.$reset();
    },
    del() {
      this.deleteItem(this.issueUpdateItem).then(() => {
        this.showIssueUpdateDialog = false;
        this.showMessage(this.deleteMessage);
        this.handleDeleted(this.issueUpdateItem);
      });
    },
    isValidForm() {
      this.formRef.$v.$touch();
      return !this.formRef.$v.$invalid;
    },
    getFormRef() {
      return this.formRefName;
    },
    uploadAttachment() {
      if (this.formRef.attachment) {
        this.uploadDocument(this.formRef.attachment, true);
      }
    },
    deleteAttachment(attachment) {
      this.$confirm(this.confirmAttachmentDeletionMessage, {
        buttonTrueText: this.$t('Yes, delete now'),
        buttonFalseText: this.$t('No'),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.deleteDocument(attachment);
        this.$store.dispatch('removeRelation', {
          iri: this.item['@id'],
          relationName: 'attachments',
          toIri: attachment['@id']
        });
        this.item.attachments.splice(
          this.item.attachments.findIndex(
            (el) => el["@id"] === attachment["@id"]
          ),
          1
        );
      });
    },
  },
  watch: {
    created(created) {
      if (!created) {
        return;
      }
      this.showMessage(this.createMessage);
      if (this.handleCreated) {
        this.handleCreated(this.created);
        this.resetForm();
        this.showIssueUpdateDialog = false;
      }
    },
    updated() {
      this.showMessage(this.updateMessage);
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
        this.showIssueUpdateDialog = false;
      }
    },
    error(message) {
      message && this.showError(message);
    },
    deleteError(message) {
      message && this.showError(message);
    },
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      if (this.formRef) {
        //TODO issue after snd upate?
        this.$store.dispatch('addRelation', {
          iri: this.item['@id'],
          relationName: 'attachments',
          toIri: this.uploadedDocument['@id']
        });
        if (!this.item.attachments.includes(this.uploadedDocument['@id'])) {
          this.item.attachments.push(this.uploadedDocument['@id']);
        }
        this.formRef.attachment = null;
      }
    },
  },
  created() {
    this.item = !isEmpty(this.issueUpdateItem)
      ? {...this.issueUpdateItem}
      : {...this.item};
  },
};
</script>
